

























































































import {Vue, Component} from 'vue-property-decorator'
import {SingleItem} from "@/handlers/interfaces/ContentUI";
import User from "@/models/User";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Handler from "@/handlers/Handler";
import CommercialService from "@/services/user/CommercialService";
import LangModule from "@/store/LangModule";
import DistributorService from "@/services/user/DistributorService";

@Component({})
export default class DistributorView extends Vue {

    loading: boolean = false
    distributor: SingleItem<User> = { item: new User() }
    lang: any = getModule(LangModule).lang

    created() {
        this.refresh()
    }

    async refresh() {
        await Handler.getItem(this, this.distributor, () =>
            DistributorService.getDistributor(Number(this.$route.params.id))
        )
    }

    setActive() {
        getModule(DialogModule).showDialog(new Dialog(
            this.lang.warning,
            "¿Esta seguro de cambiar el estado del distribuidor? Esto hará que todos sus comerciales pierdan el acceso hasta que se vuelva a activar.",
            async () => await Handler.getItem(this, this.distributor, () =>
                DistributorService.setActive(this.distributor.item.id!, !this.distributor.item.active!)
            )
        ))
    }

}
